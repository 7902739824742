<template>
  <section :style="backgroundStyle">
    <div class="header-text">
        <h1>Welcome to Temenus: A Realm of Digital Mastery</h1>
        <p>Dive into the unique world of Temenus, where every collectible isn’t just a piece of art, but an emotion, a statement, a revolution.</p>
    </div>
  </section>
</template>

<script>

import butterflyGif from '@/assets/gifs/1120-flylikeabutterfly.gif';

export default {
  name: 'HeaderSection',
  data() {
    return {
      backgroundStyle: `background: url(${butterflyGif}) no-repeat 50% 30%;`
    };
  }
}
</script>

<style scoped>

.header-text{
  width: 80vw;
  margin-bottom: 150px;
  padding: 10px;
  border-radius: 10px;
  background-color: var(--blackish);
}

p{
  padding-left: 10px;
  padding-right: 10px;
}

@media screen and (min-width: 992px) {
  .header-text{
    width: 40vw;
    padding: 30px;
    margin-bottom: 200px;
  }
  p{
  padding-left: 20px;
  padding-right: 20px;
}
}
</style>
