<template>
  <div>
    <MainPage v-if="currentPage === 'main'" />
    <ImprintPage v-if="currentPage === 'imprint'" />
    <section id="footer-section">
    <div id="footer-inner-row">
      <div id="footer-inner-inner-row">
        <div class="button" @click="showImprintPage" v-if="currentPage === 'main'"><p>Imprint</p></div>
        <div class="button" @click="showMainPage" v-if="currentPage === 'imprint'"><p>Home</p></div>
        <div class="spacer"></div>
        <div class="button" @click="sendEmail"><p>Contact</p></div>
      </div>
      <a href="https://cuckoo-coding.com/"><p>&#10084; designed and coded by cuckoo coding &#10084;</p></a>
    </div>
  </section>
  </div>
</template>


<script>
import MainPage from './components/MainPage.vue'
import ImprintPage from './components/ImprintPage.vue'


export default {
  components: {
    MainPage,
    ImprintPage,
  },
  data() {
    return {
      currentPage: 'main'
    }
  },
  methods: {
    showMainPage() {
      this.currentPage = 'main';
      window.scrollTo(0, 0);  // Scroll to top when switching pages
    },
    showImprintPage() {
      this.currentPage = 'imprint';
      window.scrollTo(0, 0);  // Scroll to top when switching pages
    },
    sendEmail() {
      const email = 'info@temenus.com';
      const subject = encodeURIComponent('Hi Temenus Labs');
      const body = encodeURIComponent('I just wanted to say hi.');

      const mailToLink = `mailto:${email}?subject=${subject}&body=${body}`;
      window.location.href = mailToLink;
    }
  }
}
</script>

<style>

@import "/src/assets/styles/colours.css";
@import "/src/assets/styles/fonts.css";
@import "/src/assets/styles/styles.css";

@font-face {
  font-family: "OverPass Mono";
  font-weight: normal;
  src:
    url('./assets/fonts/OverpassMono/OverpassMono-Regular.ttf') format("truetype");
}

@font-face {
  font-family: "OverPass Mono";
  font-weight: bold;
  src:
    url('./assets/fonts/OverpassMono/OverpassMono-Bold.ttf') format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: normal;
  src:
    url('./assets/fonts/Montserrat/Montserrat-Regular.otf') format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: bold;
  src:
    url('./assets/fonts/Montserrat/Montserrat-Bold.ttf') format("truetype");
}

@font-face {
  font-family: "Pangram";
  font-weight: bold;
  src:
    url('./assets/fonts/Pangram/Pangram-Black.otf') format("truetype");
}

body{
  margin: 0;
}

#app {
  position: relative; /* This ensures that the absolute positioning of the butterfly-container is relative to the #app element */
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  background-color: var(--violet);
  padding: 0;
}


#footer-section{
  min-height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--black);
}

#footer-inner-row{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  padding: 0;
}

#footer-inner-inner-row{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#footer-section a{
  text-decoration: none;
}

#footer-section p{
  color: var(--neongreen);
  font-size: 14px;
}

@media screen and (min-width: 992px) {

  #footer-inner-row{
    flex-direction: row;
  }

  #footer-inner-inner-row{
    flex-direction: row;
  }

  #footer-section p{
  font-size: 18px;
}

}


</style>
