<template>
  <section>
    <div class="inner-column">
      <p class="citation">»Any sufficiently advanced technology is indistinguishable from magic.«</p>
      <p>Arthur C. Clarke</p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TextSection',
}
</script>

<style scoped>

section{
  min-height: 40vh;
  background-color: var(--lightviolet);
}

.inner-column{
  width: 90%;
}

.citation{
  color: var(--darkviolet);
  font-size: 30px;
  line-height: 30px;
  font-family: "Pangram", sans-serif;
}

p{
  color: var(--darkviolet);
  font-size: 30px;
  font-family: "Pangram", sans-serif;
  text-shadow: none;
}


@media screen and (min-width: 992px) {
  .inner-column{
    width: 60%;
  }

  .citation{
  font-size: 60px;
  line-height: 60px;
}
}


</style>
