<template>
    <div class="imprint-container">
        <div class="imprint">
            <h1>Impressum</h1>
            <h2>Angaben gem. § 5 TMG</h2>
            <p>Temenus Labs</p>
            <p>TOJA</p>
            <p>Ebstorfer Weg 9</p>
            <p>30625 Hannover</p>
            <h2>Kontakt</h2>
            <p>info(at)temenus.com</p>
        </div>
    </div>  
</template>

<style scoped>
.imprint-container {
    background-color: var(--violet);
    min-height: 90vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.imprint {
    width: 90%;
}   

@media screen and (min-width: 992px) {
    .imprint {
        width: 60%;
    }
}

</style>