<template>
  <section>
    <div class="inner-column">
      <h2>Discover, embrace, and treasure – every NFT here isn’t just a token.</h2> 
      <p>It’s an emblem of what we stand for. Join us in this journey and become a part of the Temenus legacy. Each Temenus creation is more than just a visual delight; it’s a Non-Fungible Token (NFT) – an authentic digital signature, promising true ownership and unmatched rarity.</p>
      <p>At Temenus, art and collectibles transcend traditional boundaries. Each piece is featured and individually rendered, showcasing the limitless possibilities of the digital age. Our collections aren't simply generated; they're birthed from love, inspired by a desire for peace, and fueled by a resolute commitment to save the planet.</p>
      <div class="button" @click="openOpensea"><p>Explore our collections on Opensea</p></div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'TextSection',
  methods: {
    openOpensea() {
      window.open('https://opensea.io/collections', '_blank');
    },
  }
}
</script>

<style scoped>

section{
  min-height: 40vh;
  background-color: var(--darkviolet);
}
</style>
