<!-- eslint-disable vue/require-v-for-key -->
<template>
    <!-- Butterfly Overlay -->
    <div ref="butterflyContainer" class="butterfly-container" @mouseover.once="scatterButterflies" @scroll.once="scatterButterflies" @onclick.once="scatterButterflies">
      <img class="butterfly"
        v-for="butterfly in butterflies"
        :src="butterfly.src"
        :style="`width: ${butterfly.width}px; height: ${butterfly.height}px; left: ${butterfly.left}px; top: ${butterfly.top}px; position: absolute; transform: rotate(${butterfly.rotation}deg);`"
      />
    </div>
    <!-- Rest of the page -->
    <HeaderSection/>
    <TextSection/>
    <CollectionSection/>
    <CitationSection/>
  </template>

<script>
import HeaderSection from './HeaderSection.vue'
import TextSection from './TextSection.vue'
import CollectionSection from './CollectionSection.vue'
import CitationSection from './CitationSection.vue'
import Butterfly1 from '@/assets/gifs/1120-flylikeabutterfly.gif';
import Butterfly2 from '@/assets/gifs/1122-flylikeabutterfly.gif';
import Butterfly3 from '@/assets/gifs/1124-flylikeabutterfly.gif';
import Butterfly4 from '@/assets/gifs/1125-flylikeabutterfly.gif';

export default {
  name: 'App',
  components: {
    HeaderSection,
    CollectionSection,
    TextSection,
    CitationSection,
  },
  data() {
    return {
      butterflies: [],
      butterflyGifs: [
      Butterfly1,
      Butterfly2,
      Butterfly3,
      Butterfly4
    ]
    }
  },
  methods: {
    scatterButterflies() {
      const butterflies = this.$refs.butterflyContainer.querySelectorAll('.butterfly');
      butterflies.forEach(butterfly => {
        // Decide direction for the butterfly to fly out: up, down, left, or right
        const direction = Math.floor(Math.random() * 4);
        switch (direction) {
          case 0: // Up
            butterfly.style.top = `calc(${butterfly.style.top} - ${window.innerHeight}px)`;
            break;
          case 1: // Down
            butterfly.style.top = `calc(${butterfly.style.top} + ${window.innerHeight}px)`;
            break;
          case 2: // Left
            butterfly.style.left = `calc(${butterfly.style.left} - ${window.innerWidth}px)`;
            break;
          case 3: // Right
            butterfly.style.left = `calc(${butterfly.style.left} + ${window.innerWidth}px)`;
            break;
        }
      
        // Listen to the transitionend event
        butterfly.addEventListener('transitionend', () => {
          butterfly.style.display = 'none'; // Hide the butterfly after scattering
        }, { once: true }); // The listener will be removed after executing once
      });
    }
  },
  mounted() {
    // To always start at the top of the page when returning from another page
    window.scrollTo(0, 0);

    const ratio = 1.4141475212

    const gridX = 80
    const gridY = 60
    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight
    const randomFactorSize = 150
    const randomFactorPosition = 20
    const randomFactorRotation = 45

    const numberOfButterfliesX = Math.floor(windowWidth / gridX)
    const numberOfButterfliesY = Math.floor(windowHeight / gridY)

    console.log(numberOfButterfliesX, numberOfButterfliesY)

    for (let ix = 0; ix < numberOfButterfliesX; ix++) {
      for (let iy = 0; iy < numberOfButterfliesY; iy++) {
        const width = randomFactorSize + Math.random() * randomFactorSize - randomFactorSize / 2
        const randomGif = this.butterflyGifs[Math.floor(Math.random() * this.butterflyGifs.length)];
        this.butterflies.push({
          src: randomGif,
          width,
          height: width / ratio,
          left: ix * gridX + Math.random() * randomFactorPosition - randomFactorPosition / 2,
          top: iy * gridY + Math.random() * randomFactorPosition - randomFactorPosition / 2,
          rotation: Math.random() * randomFactorRotation - randomFactorRotation / 2
        })
      }
    }
  },
}
</script>

<style scoped>

.butterfly-container {
  position: absolute; /* Absolute positioning to overlay content */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999; /* Ensures it's on top of other content */
  overflow: hidden; /* To ensure any off-screen butterflies don't cause scrolling */
}

.butterfly {
  transition: top 1s, left 1s; /* Adjust the duration as needed */
}
</style>