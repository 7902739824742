<template>
  <section>
    <div class="inner-div">
      <div class="card" @click="openOpensea">
        <img src="@/assets/images/303-damrillasunglass.png" alt="Damrilla Sunglass NFT Collection">
        <h3>Damrilla's</h3>
      </div>
      <div class="card" @click="openOpensea">
        <img src="@/assets/images/1110-sunstation.png" alt="Sunstation NFT Collection">
        <h3>Sunstation</h3>
      </div>
      <div class="card" @click="openOpensea">
        <img src="@/assets/gifs/1120-flylikeabutterfly.gif" alt="Fly like a butterfly NFT Collection">
        <h3>Fly like a butterfly</h3>
      </div>
      <div class="card" @click="openOpensea">
        <img src="@/assets/images/1474-scooterpixel.png" alt="Scooter Y NFT Collection">
        <h3>Scooter Y</h3>
      </div>
      <div class="card" @click="openOpensea">
        <img src="@/assets/images/1765-scooterpoly.png" alt="Scooter X NFT Collection">
        <h3>Scooter X</h3>
      </div>
      <div class="card" @click="openOpensea">
        <img src="@/assets/images/2004-blackswan.png" alt="Black Swan NFT Collection">
        <h3>Black Swan</h3>
      </div>
      <div class="card" @click="openOpensea">
        <img src="@/assets/images/2010-mmdpo.jpg" alt="MMDPO NFT Collection">
        <h3>Girl with pearl</h3>
      </div>
      <div class="card" @click="openOpensea">
        <img src="@/assets/images/2231-sunstation.png" alt="Sunstation NFT Collection">
        <h3>Sunstation</h3>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CollectionSection',

mounted() {
    this.addIntersectionObserver();
  },

  methods: {
    openOpensea() {
      window.open('https://opensea.io/Temenus', '_blank');
    },
    addIntersectionObserver() {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const cards = this.$el.querySelectorAll('.card');
            cards.forEach((card, index) => {
              setTimeout(() => {
                card.style.visibility = 'visible';
                card.style.transform = 'scale(1)';
              }, 100 * index);
            });
            observer.unobserve(entry.target);
          }
        });
      });

      observer.observe(this.$el);
    }
  }
}
</script>

<style scoped>
section {
  background-color: var(--neongreen);
}

.inner-div {
  width: 90%;
  height: 100%;
  padding: 50px;
  display: grid;
  grid-template-columns: 1fr; 
  gap: 16px; /* Adjust this value to control space between cards */
  perspective: 1000px; /* Needed for 3D animation effect */
  background-color: var(--neongreen);
}

@media screen and (min-width: 992px) {
  .inner-div {
    width: 70%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.card {
  position: relative;
  width: 100%;
  padding-bottom: 80%; /* This gives the rectangle shape */
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden; /* To keep the image and content inside card bounds */
  cursor: pointer;
  transform: scale(0.9);
  transition: transform 0.3s ease-out;
  transform-origin: center;
  visibility: hidden;
}

.card:hover {
  transform: scale(0.92); /* A little scale on hover for effect */
}

.card img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.card h3 {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: var(--blackish);
  padding: 5px 10px;
  border-radius: 5px;
  text-shadow: lightgreen 0px 0px 3px;
}

</style>
